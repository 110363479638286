export const cpfMask = (cpf) => {
    if (!cpf) return;
    var num = cpf.replace(/[^\d]/g, ''); //remove todos os caracteres não numéricos
    var len = num.length; //guarda o número de digitos até o momento

    if (len <= 6) {
        cpf = num.replace(/(\d{3})(\d{1,3})/g, '$1.$2');
    } else if (len <= 9) {
        cpf = num.replace(/(\d{3})(\d{3})(\d{1,3})/g, '$1.$2.$3');
    } else {
        cpf = num.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/g, "$1.$2.$3-$4");
    }
    return cpf;
}

export const removeInputMask = (cpf) => {
    if (!cpf || cpf === "") {
        return ""
    } else {
        return cpf.replace(/[^\d]+/g, "");
    }
};
 
export const formatDate = (value) => value && `${value.slice(8, 10)}/${value.slice(5, 7)}/${value.slice(0, 4)}`

export const formatDateISO = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
  
    // Utilizar métodos UTC para evitar o impacto do timezone
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
  
    return `${year}-${month}-${day}`;
};

export const formatDateTime = (dateString) => {
    if (!dateString) return null;
    const isUTC = dateString.endsWith("Z") || dateString.includes("+00:00");
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("pt-BR", {
      timeZone: isUTC ? "UTC" : undefined, // Usa UTC apenas se a string indicar UTC
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // 24h
    });
    return formatter.format(date);
}

export const today = () => new Date().toISOString().slice(0, 10);

export const rowspan = {
    setTd : (value, fieldName) => {
        let cont = 0;
        value.forEach(
            (r) => {
                if (cont > 0) {
                    cont--;
                    r.HIDECOL = true;
                    return;
                }
                value.forEach(
                    (s) => {
                        if (r[fieldName] === s[fieldName]) {
                            cont++;
                        }
                    }
                )
                r.ROWSPAN = cont;
                r.HIDECOL = false;
                cont--;
            }
        )
        return value;
    },
    td : (r, value) => (r.ROWSPAN > 1) ? <td rowspan={r.ROWSPAN}>{value}</td> : r.HIDECOL ? "" : <td>{value}</td>
}

export const formatarCEP = (cep) => {
    if ((typeof cep === 'undefined') || (cep === null)) return cep;
    // Deixa apenas o número
    cep = cep.replace(/\D/g, '');
  
    // Aplica a máscara no formato XXXXX-XXX
    if (cep.length > 5) {
      cep = cep.replace(/(\d{5})(\d{1,3})/, '$1-$2');
    }
  
    return cep;
}

// Função para calcular o dígito verificador
const calcularDigito = (base) => {
    let soma = 0;
    for (let i = 0; i < base.length; i++) {
        soma += parseInt(base[i]) * (base.length + 1 - i);
    }
    const resto = soma % 11;
    return resto < 2 ? 0 : 11 - resto;
};
export const isCpfValid = (cpf) => {
    if (!cpf) return false;
    cpf = cpf.replace(/[^\d]/g, '');
    if (cpf.length !== 11) return false;
    // Verifica se todos os dígitos são iguais (ex: 111.111.111-11)
    if (/^(\d)\1+$/.test(cpf)) return false;
    // Calcula os dois dígitos verificadores
    const base = cpf.slice(0, 9);
    const digito1 = calcularDigito(base);
    const digito2 = calcularDigito(base + digito1);
    // Verifica se os dígitos calculados correspondem aos informados
    return cpf === base + digito1 + digito2;
}