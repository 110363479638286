import React, { useState } from 'react';
import SearchCustomerService from '../../Components/SearchCustomerService';

export default function CustomerService(props) {
    const [ loading, setLoading] = useState(false);
    const [ erroMsg, setErroMsg ] = useState('');
    const [ modalError, setModalError ] = useState(false);
    const [ customerServiceList, setCustomerServiceList ] = useState(undefined)
    const [ protocolo, setProtocolo ] = useState(null);

    const onSubmitSearch = async (data) => {
        setLoading(true);
        setErroMsg('');
        setProtocolo(data.protocolo);
        const user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': user ? user.token : ""
            }),
            body: JSON.stringify({
                protocolo: data.protocolo,
                cpf: user.cpf_atendente,
                pagina_atual: "1",
            }),
        };

        await fetch(`${process.env.REACT_APP_API_URL}/atendimento/consultaatendimento`, requestOptions).then(function(response) {
            setLoading(false);
            if (response.ok) {
                response.json().then(function(resp) {
                    setCustomerServiceList(resp)
                })
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        setErroMsg(data.message);
                    } else {
                        setErroMsg('Houve algum problema no servidor.');
                    }
                });
            } else {
                setModalError(true);
                console.log('Request error in atendimento/consultaatendimento BackendBalcao');
            }
            setLoading(false);
        })
    };

    const onSubmitExtractDoc = async (idDocumento) => {
        setLoading(true);
        setErroMsg('');
        const user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': user ? user.token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: user.cpf_atendente,
                id_atendimento: protocolo,
                id_documento: idDocumento
            }),
        };

        await fetch(`${process.env.REACT_APP_API_URL}/documentos/extrairDocumento`, requestOptions).then(function(response) {
            setLoading(false);
            if (response.ok) {
                response.json().then(function(resp) {
                    const byteCharacters = atob(resp.DOCUMENTO.split(',')[1]);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: "application/pdf" });
                    // Cria um link temporário para o download
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = "documento.pdf";
                    // Adiciona o link ao DOM, clica nele e remove
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        setErroMsg(data.message);
                    } else {
                        setErroMsg('Houve algum problema no servidor.');
                    }
                });
            } else {
                setModalError(true);
                console.log('Request error in documentos/extrairDocumento BackendBalcao');
            }
            setLoading(false);
        })
    };

    return(
       <SearchCustomerService
            pageTitle='Consulta de Atendimento'
            buttonRegistrationTitle='+ Cadastrar Atendente'
            buttonRegistrationRoute='/consultaratendente'
            fieldNamePlaceholder='Digite o nome do atendente'
            buttonDetailRoute='/detalharatendente'
            buttonEditRoute='/editaratendente'
            buttonCancelRoute='/cancelaratendente'
            buttonNextLoading={loading}
            onSubmitSearch={onSubmitSearch}
            searchResult={customerServiceList}
            setSearchResult={setCustomerServiceList}
            history={props.history}
            erroMsg={erroMsg}
            setErroMsg={setErroMsg}
            modalError={modalError}
            setModalError={setModalError}
            onSubmitExtractDoc={onSubmitExtractDoc}
       />
    );
}