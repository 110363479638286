import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import {
    ReportParameterContainer,
    DoubleListContainer,
    DoubleListSubContainer,
    ListContainer,
    ListContainerLeft,
    ListContainerRight,
    Ul,
    Subtitle,
    Line,
    SearchButtonsContainer
} from './styles';
import FilterIcon from '../../assets/filter_icon.png';
import ModalCancel from '../Modal/Cancel';
import ModalWarn from '../Modal/Warn/index';

export default function ReportInputSelectElements(props) {
    const [modalCancel, setModalCancel] = useState(false);
    const [enableBtnReportGenerate, setEnableBtnReportGenerate] = useState(false);
    const { handleSubmit } = useForm();

    let rightListItems = []
    function moveItem(element, targetListId) {        
        document.getElementById(targetListId).appendChild(element);
        const value = element.dataset.value;
        const item = props.organOptions.find(i => i.value === value);
        if (targetListId === 'right-list') {
            rightListItems.push(item);
        } else {
            rightListItems = rightListItems.filter(i => i.value !== value);
        }
        
        props.setSelectedElements(rightListItems);
        props.setResult(null);
        props.setTotal(null);
        setEnableBtnReportGenerate(rightListItems.length > 0);
        document.getElementById("left-filter").value = "";
        filterList('left-list', "");
        document.getElementById("right-filter").value = "";
        filterList('right-list', "");
        
        element.onclick = function() {
            moveItem(this, element.parentElement.id === 'left-list' ? 'right-list' : 'left-list');
        };
    }
    function filterList(listId, query) {
        const list = document.getElementById(listId);
        const items = list.getElementsByTagName("li");
        Array.from(items).forEach(item => {
            item.style.display = item.textContent.toLowerCase().includes(query.toLowerCase()) ? "block" : "none";
        });
    }
    function renderList() {
        const leftList = document.getElementById("left-list");
        leftList.innerHTML = "";
        props.organOptions.forEach(item => {
            const li = document.createElement("li");
            li.textContent = item.label;
            li.dataset.value = item.value;
            li.onclick = function() {
                moveItem(this, 'right-list');
            };
            leftList.appendChild(li);
        });
    }

    useEffect(() => {
        renderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.organOptions]);

    return (
        <>
            <ReportParameterContainer>
                <ModalCancel
                    id="modalcancelalert"
                    appear={modalCancel}
                    text={"Deseja retornar para a página anterior?"}
                    actionButton1={() => setModalCancel(false)}
                    actionButton2={() => props.history.push("/relatorios")}
                />
                <ModalWarn
                    id="modalconfirmalert"
                    appear={props.modalError}
                    text={props.messageError}
                    textButton2='Ok'
                    actionButton2={() => props.setModalError(false)}
                />
                <Subtitle>
                    <img
                        src={FilterIcon}
                        alt="filterIcon"
                    />
                    <label> Selecione o(s) Órgão(s) </label>
                    <Line />
                </Subtitle>
                <form onSubmit={handleSubmit(() => props.onSubmitSearch())}>
                    <DoubleListContainer>
                        <DoubleListSubContainer>
                            <ListContainerLeft>
                                <label>Disponíveis</label>
                                <input type="text" id="left-filter" placeholder="Filtrar..."  onInput={e =>filterList('left-list', e.target.value)} />
                                <ListContainer>                              
                                    <Ul id="left-list"></Ul>
                                </ListContainer>
                            </ListContainerLeft>
                            <ListContainerRight>
                                <label>Selecionados</label>
                                <input type="text" id="right-filter" placeholder="Filtrar..." onInput={e =>filterList('right-list', e.target.value)}/>
                                <ListContainer>                            
                                    <Ul id="right-list"></Ul>
                                </ListContainer>
                            </ListContainerRight>
                        </DoubleListSubContainer>
                    </DoubleListContainer>

                        <SearchButtonsContainer>
                            <button className="br-button secundary" type="button"
                                onClick={() => setModalCancel(true)}>
                                Voltar
                            </button>
                            <button className="br-button terciary" type="button" disabled={!props.result}
                                onClick={() => props.csvGenerator()}>
                                Baixar arquivo csv
                            </button>
                            <button className="br-button terciary" type="button" disabled={!props.result}
                                onClick={() => window.print()}>
                                Imprimir
                            </button>
                            <button className={props.loadingReport ? "br-button primary loading" : "br-button primary"} type="submit" disabled={ !enableBtnReportGenerate }>
                                Gerar relatório
                            </button>
                        </SearchButtonsContainer>

                    <Subtitle>
                        <Line />
                    </Subtitle>
                </form>
            </ReportParameterContainer>
        </>
    );
}