import React, { useState, useEffect } from 'react';
import {
    GlobalContainer,
    ContentContainer,
    ReportContainer,
    TitleContainer,
    Title,
    Subtitle,
    Line
} from '../styles';
import AlertIcon from '../../assets/alert_icon2.png';
import { formatDateTime } from '../../utils';
import ReportInputSelectElements from '../../Components/ReportInputSelectElements'
import ModalWarn from '../../Components/Modal/Warn/index'

export default function UnitsByAgencyAdminReport(props) {
    const [selectedAgencies, setSelectedAgencies] = useState([]);
    const [loadingReport, setLoadingReport] = useState(false);
    const [result, setResult] = useState();
    const [total, setTotal] = useState();
    const [resultCsv, setResultCsv] = useState([]);
    const [searchErro, setSearchErro] = useState(false);
    const [searchErroMessage, setSearchErroMessage] = useState("");
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)
    const [ organOptions, setOrganOptions ] = useState([]);

    useEffect(() => {
        async function loadOrganOptions() {
            setLoadingReport(true);
            setSearchErro(false);
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    cpf_usuario: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : ""
                }),
            };
            
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/consultaOrgaoGestorOrgao`, requestOptions).then(
                function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
    
                        var organList = [];
                        var obj = {};

                        data.forEach((organ) => {
                            obj = {
                                value: organ.CODIGO_ORGAO,
                                label: organ.NOME_ORGAO
                            }

                            organList.push(obj);
                        });

                        setOrganOptions(organList);
                    });
                } else {
                    setSearchErro(true);
                    setSearchErroMessage('Houve algum problema no servidor.');
                    console.log('Error request to /gestao/consultaOrgaoGestorOrgao');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
            setLoadingReport(false);
        }

        loadOrganOptions();
    }, [props.location.state]);

    const onSubmitSearch = async () => {
        setLoadingReport(true);
        setSearchErro(false);

        const user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': user ? user.token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: user ? user.cpf_atendente : "",  //Neste caso, o cpf_atendente se refere ao gestor que está gerando o relatório
                orgaos: selectedAgencies && selectedAgencies.map(e=> e.value)
            }),
        };

        await fetch(`${process.env.REACT_APP_API_URL}/gestao/relUnidadesPorOrgao`, requestOptions).then(
            function (response) {
                if (response.ok) {
                    response.json().then(function (data) {
                        setResultCsv(data);
                        const groupedData = {};
                        const groupedTotal = {};
                        // eslint-disable-next-line array-callback-return
                        data.map((row, i) => {
                            const orgao = row["NOME_ORGAO"];
                            if (!groupedData[orgao]) {
                                groupedData[orgao] = [];
                            }
                            groupedData[orgao].push(row);
                        })
                        setResult(groupedData);
                        Object.entries(groupedData).map(([orgao, unidades, i]) => {
                            if (!groupedTotal[orgao]) groupedTotal[orgao] = {}
                            groupedTotal[orgao].nUnidade = unidades.length;
                            let nGestores = 0;
                            let nAtendentes = 0;
                            unidades.map((r, i) => {
                                nGestores = nGestores + Number(r.QTDE_GESTORES_REGIONAIS || 0);
                                nAtendentes = nAtendentes + Number(r.QTDE_ATENDENTES || 0);
                                return null;
                            });
                            groupedTotal[orgao].nGestores = nGestores;
                            groupedTotal[orgao].nAtendentes = nAtendentes;
                            return null;
                        });
                        setTotal(groupedTotal);
                    });
                } else if (response.status === 422) {
                    response.json().then(function (data) {
                        setSearchErro(true);
                        if (!!data.errorCode && !!data.messages) {
                            setSearchErroMessage(data.messages[0].message);
                        } else if (!!data.errorCode && !!data.message) {
                            setSearchErroMessage(data.message);
                        } else {
                            setSearchErroMessage('Houve algum problema no servidor.');
                        }
                    });
                } else if (response.status === 403) {
                    response.json().then(function (data) {
                      setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                      setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                      setModalInvalidSessionError(true)
                    })
                } else if (response.status === 400) {
                    response.json().then(function (data) {
                        if (!!data.errorCode && !!data.messages) {
                            setSearchErro(true);
                            setSearchErroMessage(data.messages[0].message);
                        } else {
                            setSearchErro(true);
                            setSearchErroMessage('Houve algum problema no servidor.');
                        }
                    });
                } else {
                    setSearchErro(true);
                    setSearchErroMessage('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                }
            }).catch(function (err) {
                console.error('Failed retrieving information', err);
            });

        setLoadingReport(false);
    }

    const assembleAddress = (r) => {
        return (r.LOGRADOURO ? r.LOGRADOURO + ', ' : '') + (r.NUMERO ? r.NUMERO + ', ' : '') + (r.COMPLEMENTO ? r.COMPLEMENTO + ', ' : '') + (r.BAIRRO ? r.BAIRRO + ', ' : '') + (r.CEP || '');
    }

    const csvGenerator = () => {
        let csvContent = "NOME_ORGAO;NOME_UNIDADE;ESTADO;CIDADE;ENDERECO;QTDE_GESTORES_REGIONAIS;QTDE_ATENDENTES;DATA_ULTIMO_ATENDIMENTO\n";
        csvContent = csvContent + resultCsv.map(r => r.NOME_ORGAO + ";" + r.NOME_UNIDADE + ";" + (r.ESTADO || '') + ";" + (r.CIDADE || '') + ";" + assembleAddress(r) + ";" + (r.QTDE_GESTORES_REGIONAIS || 0) + ";" + (r.QTDE_ATENDENTES || 0) + ";" + (formatDateTime(r.LAST_ATD) || '')).join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        // Cria um link temporário para o download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "unidadesPorOrgaos.csv";
        // Adiciona o link ao DOM, clica nele e remove
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <GlobalContainer>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <ContentContainer>
                <TitleContainer>
                    <Title>Unidades de atendimento por órgãos</Title>
                </TitleContainer>
                <ReportInputSelectElements
                    onSubmitSearch={onSubmitSearch}
                    selectedElements={selectedAgencies}
                    setSelectedElements={setSelectedAgencies}
                    loadingReport={loadingReport}
                    organOptions={organOptions}
                    result={result}
                    setResult={setResult}
                    total={total}
                    setTotal={setTotal}
                    modalError={searchErro}
                    setModalError={setSearchErro}
                    messageError={searchErroMessage}
                    history={props.history}
                    csvGenerator={csvGenerator}
                />
                {(result && Object.entries(result).length > 0) ?
                    <ReportContainer>
                        <Subtitle><label>Relatório extraído em {formatDateTime(Date())}</label></Subtitle>
                        <div className="br-table" data-search="data-search" data-selection="data-selection">
                            {Object.entries(result).map(([orgao, unidades, i]) => (
                                <>
                                
                                <table>
                                    <thead>
                                        <tr>
                                            <th colspan="7" className="orgao-header">Órgão: {orgao}</th>
                                        </tr>
                                        <tr>
                                            <th>Nome da Unidade</th>
                                            <th>Estado</th>
                                            <th>Cidade/Município</th>
                                            <th>Endereço</th>
                                            <th>N. de gestores ativos</th>
                                            <th>N. de atendentes ativos</th>
                                            <th>Data do último atendimento</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {unidades.map((r, i) => (
                                        <tr>
                                                <td>{r.NOME_UNIDADE}</td>
                                                <td>{r.ESTADO || ''}</td>
                                                <td>{r.CIDADE || ''}</td>
                                                <td className='tdEndereco'>{assembleAddress(r)}</td>
                                                <td>{r.QTDE_GESTORES_REGIONAIS || 0}</td>
                                                <td>{r.QTDE_ATENDENTES || 0}</td>
                                                <td>{formatDateTime(r.LAST_ATD) || ''}</td>
                                            </tr>
                                        )
                                        )}
                                        <tr style={{"backgroundColor": "#ededed"}}>
                                            <td><label>{total && total[orgao].nUnidade} Unidade(s)</label></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><label>{total && total[orgao].nGestores}</label></td>
                                            <td><label>{total && total[orgao].nAtendentes}</label></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Subtitle>
                                    <Line />
                                </Subtitle>
                                </>
                            ))}
                        </div>  
                        
                    </ReportContainer>
                    :
                    <>
                        {result && Object.entries(result).length === 0 &&
                            <span className="feedback warning" role="alert" id="listresult">
                                <i className="fas fa-times-circle" aria-hidden="true">
                                    <img
                                        src={AlertIcon}
                                        style={{ paddingBottom: '5px' }}
                                        alt="failicon"
                                    />
                                </i>
                                <label id="listresultlabel">Não existe unidade cadastrada para o órgão selecionado.</label>
                            </span>
                        }
                    </>
                }
            </ContentContainer>
        </GlobalContainer>
    );
}