import React, { useState, useEffect } from 'react'

import { useForm } from 'react-hook-form'

import {
  GlobalContainer,
  TitleContainer,
  Title,
  Text,
  SelectButton,
  ContentContainer,
  ContentContainer1,
  ContentContainer2,
  ContentContainer21,
  ContentContainer22,
  ContentContainer221,
  ContentContainer222,
  ContentContainer23,
  ContentContainer24,
} from './styles'
import ModalWarn from '../../Components/Modal/Warn/index'
import ModalConfirm from '../../Components/Modal/Confirm'
import ModalUnitChoice from '../../Components/Modal/UnitChoice'

//import '../../../node_modules/@govbr/dsgov/dist/dsgov.min.css';

import descImg from '../../assets/marca_balcao_govbr.png'
import citizenImgSelect from '../../assets/botao_cidadao.png'
import attorneyImgSelect from '../../assets/botao_procurador.png'
import FailIcon from '../../assets/fail_icon.png'
import { cpfMask, removeInputMask, isCpfValid } from '../../utils'
import { profile } from '../../constants'

export default function SearchCitizen(props) {
  const state = {
    attendent: '',
    person: 'cidadao',
    cpf: '',
  }
  const [loading, setLoading] = useState(false)

  const [modalError, setModalError] = useState(false)
  const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
  const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
  const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

  const [ isModalWarnSessionalert, setModalWarnSessionalert ] = useState(false); 
  const [modalUnitOption, setModalUnitOption] = useState(false);

  const { register, handleSubmit, watch, errors } = useForm()
  const watchSelect = watch('person', state.person)

  const { person } = state

  const [cpf, setCpf] = useState("");
  const [showInvalidErroCpf, setShowInvalidErroCpf] = useState(false);

  useEffect (() => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    setModalWarnSessionalert(userInfo.sessao_previa)
    setModalUnitOption(!(userInfo.codigo_unidade && userInfo.codigo_unidade.trim().length > 0) && userInfo.lista_unidades_options.length > 1)
  }, []);

  const onSubmit = async (data) => {
    setLoading(true)

    document.getElementById('cpfattorney').style.display = 'none'
    document.getElementById('cpfcitizen').style.display = 'none'

    const user = JSON.parse(localStorage.getItem('user'))

    data.cpf = removeInputMask(data.cpf)

    // CONSULTA CIDADAO
    if (data.person === 'cidadao') {
      var citizenData = {
        id_attendance: '',
        attendent: '', // <- preenchido pelo localStorage do login
        cpf_attendant: '', // <- preenchido pelo localStorage do login
        person: '', // <- preenchido pelo atendente (select button)
        ident_dig: '', // o resto sera preenchido pelo conectagov ou Rfb
        cpf: '',
        name: '',
        birth_date: '',
        mothers_name: '',
        email: '',
        cell: '',
        documents: '', // <- so sera usado na pagina citizenData se vier do fluxo procurador
      }

      const requestOptions = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          authorization: user ? user.token : '',
        }),
        body: JSON.stringify({
          id_usuario_orgao: user.id_usuario_orgao,
          cpf_atendente: user.cpf_atendente,
          cpf_cidadao: data.cpf,
          fluxo_atendimento: 'C',
          codigo_unidade: user.codigo_unidade
        }),
      }
      await fetch(`${process.env.REACT_APP_API_URL}/atendimento/cidadao`, requestOptions)
        .then(function (response) {
          if (response.ok) {
            response.json().then(function (data) {
              // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

              if (data.status === 'BL') {
                document.getElementById('cpfcitizen').style.display = 'block'
                document.getElementById('cpfcitizenlabel').innerText = 'CPF com conta bloqueada por decisão administrativa ou judicial.'
                setLoading(false)
                return
              }

              if (data.nome_cidadao) {
                citizenData = {
                  id_attendance: data.id_atendimento,
                  attendent: user.nome_atendente,
                  cpf_attendant: user.cpf_atendente,
                  person: 'cidadao',
                  ident_dig: data.id_digital === 'true' ? true : false,
                  cpf: data.cpf_cidadao,
                  selo_conta: data.selo_conta,
                  name: data.nome_cidadao,
                  social_name: data.nome_social,
                  birth_date: data.data_nascimento,
                  mothers_name: data.nome_mae,
                  email: data.email_cidadao,
                  cell: data.celular_cidadao,
                  documents: '',
                }

                setLoading(false)
                props.history.push('/dadoscidadao', citizenData)
              } else {
                setLoading(false)
                setModalError(true)
                console.log('Response error in atendimento/cidadao BackendBalcao')
              }
            })
          } else if (response.status === 422) {
            response.json().then(function (data) {
              if (!!data.errorCode && !!data.message) {
                // inserir a mensagem: data.message no aviso de erro
                document.getElementById('cpfcitizen').style.display = 'block'
                document.getElementById('cpfcitizenlabel').innerText = data.message
              } else {
                // inserir mensagem genérica
                setLoading(false)
                setModalError(true)
                console.log('Response error in atendimento/cidadao BackendBalcao')
              }
              setLoading(false)
            })
          } else if (response.status === 403) {
            response.json().then(function (data) {
              setModalInvalidSessionErrorTitle(data.title ? data.title : null)
              setModalInvalidSessionErrorMsg(data.message ? data.message : null)
              setLoading(false)
              setModalInvalidSessionError(true)
            })
          } else {
            // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
            setLoading(false)
            setModalError(true)
            console.log('Request error in atendimento/cidadao BackendBalcao')
          }
        })
        .catch(function (err) {
          console.error('Failed retrieving information', err)
        })
    }
    // CONSULTA PROCURADOR
    else {
      var attorneyData = {
        id_attendance: '',
        attendent: '',
        cpf_attendant: '',
        person: '',
        cpf_attorney: '',
        name_attorney: '',
        document_list: '',
        cpf_citizen: '',
      }

      const requestOptions = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          authorization: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : '',
        }),
        body: JSON.stringify({
          id_usuario_orgao: user.id_usuario_orgao,
          cpf_atendente: user.cpf_atendente,
          cpf_procurador: data.cpf,
          fluxo_atendimento: 'P',
          codigo_unidade: user.codigo_unidade
        }),
      }
      await fetch(`${process.env.REACT_APP_API_URL}/atendimento/procurador`, requestOptions)
        .then(function (response) {
          if (response.ok) {
            response.json().then(function (data) {
              // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

              if (data.nome_procurador) {
                attorneyData = {
                  id_attendance: data.id_atendimento,
                  attendent: user.nome_atendente,
                  cpf_attendant: user.cpf_atendente,
                  person: 'procurador',
                  cpf_attorney: data.cpf_procurador,
                  name_attorney: data.nome_procurador,
                  social_name_attorney: data.nome_social_procurador,
                  document_list: '',
                  cpf_citizen: '',
                }

                setLoading(false)
                props.history.push('/dadosprocurador', attorneyData)
              } else {
                setLoading(false)
                setModalError(true)
                console.log('Response error in atendimento/procurador BackendBalcao')
              }
            })
          } else if (response.status === 422) {
            response.json().then(function (data) {
              if (!!data.errorCode && !!data.message) {
                // inserir a mensagem: data.message no aviso de erro
                document.getElementById('cpfattorney').style.display = 'block'
                document.getElementById('cpfattorneylabel').innerText = data.message
              } else {
                // inserir mensagem genérica
                setLoading(false)
                setModalError(true)
                console.log('Response error in atendimento/procurador BackendBalcao')
              }
              setLoading(false)
            })
          } else if (response.status === 403) {
            response.json().then(function (data) {
              setModalInvalidSessionErrorTitle(data.title ? data.title : null)
              setModalInvalidSessionErrorMsg(data.message ? data.message : null)
              setLoading(false)
              setModalInvalidSessionError(true)
            })
          } else {
            // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
            setLoading(false)
            setModalError(true)
            console.log('Request error in atendimento/procurador BackendBalcao')
          }
        })
        .catch(function (err) {
          console.error('Failed retrieving information', err)
        })
    }
  }

  const perfil = JSON.parse(localStorage.getItem('user')).perfil
  
  return (
    <>
      <ModalWarn
        id='modalconfirmalert'
        appear={modalError}
        text={ 'Houve algum problema no servidor. Aguarde um momento e tente novamente.'}
        textButton2='Ok'
        actionButton2={() => setModalError(false)}
      />
      <ModalWarn
        id='modalWarnalertInvalidSession'
        appear={modalInvalidSessionError}
        title = { modalInvalidSessionErrorTitle }
        text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
        textButton2='Ir para tela inicial'
        actionButton2={() => { 
          document.getElementById("btnLogout").click();
        }}
      />
      <ModalConfirm 
          id="modalWarnSessionalert"
          appear={isModalWarnSessionalert}
          title={"Deseja transferir seu acesso ao Balcão gov.br para este dispositivo?"}
          text={'Você já está logado no Balcao gov.br em outro dispositivo e não é permitido mais de um acesso simultâneo.'}
          textButton1={"Manter acesso anterior"}
          textButton2={"Transferir para este dispositivo"}       
          actionButton1={() =>{
            document.getElementById("btnLogout").click();
          }}   
          actionButton2={() => {
            setLoading(true)
            const requestOptions = {
              method: 'POST',
              headers: new Headers({
                'Content-Type': 'application/json',
                authorization: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : '',
              }),
            }
            fetch(`${process.env.REACT_APP_API_URL}/login/sessionRegistration`, requestOptions).then(function (response) {
              if (response.ok) {
                let user = JSON.parse(localStorage.getItem('user'))
                user.sessao_previa = false;
                localStorage.setItem('user', JSON.stringify(user));
                setLoading(false)
                setModalWarnSessionalert(false)
              } else {
                // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                setLoading(false)
                setModalWarnSessionalert(false)
                setModalError(true)
              }
            }).catch(function (err) {
              // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
              setLoading(false)
              setModalWarnSessionalert(false)
              setModalError(true)
            })
          }}
      />
      <ModalUnitChoice 
          id="modalUnitOption"
          appear={modalUnitOption}
          unitListOptions={JSON.parse(localStorage.getItem('user')).lista_unidades_options}
          title={"Escolha sua unidade."}
          textButton1={"Ok"}  
          errorMessage={"Por favor, escolha sua unidade."}  
          actionButton1={(chosenUnit) => {
            let user = JSON.parse(localStorage.getItem('user'))
            user.codigo_unidade = chosenUnit.value;
            user.unidade = chosenUnit.label;
            localStorage.setItem('user', JSON.stringify(user));
            setModalUnitOption(false);
            window.location.reload();
          }}
      />
      <GlobalContainer>
        <TitleContainer>
          <Title> Criação e Atualização de Conta gov.br </Title>
        </TitleContainer>

        <ContentContainer>
          <ContentContainer1>
            <img className='content_container_1_img' src={descImg} alt='descricaoimg' />

            <div>
              <label>Você pode criar uma Conta gov.br para um cidadão (proprietário da Conta) ou para um procurador (pessoa que representa o proprietário).</label>
            </div>

            <div className='br-magic-button' style={{ marginTop: '30px', display: 'grid' }}>
              {perfil === profile.gestorSistema && (
                <>
                  <button className='br-button' type='button' onClick={() => props.history.push('/gerenciarGestoresOrgao')}>
                    Acessar Gestão de Gestores de Órgão
                  </button>
                  <button className='br-button' type='button' style={{ marginTop: '30px' }} onClick={() => props.history.push('/bloquearDesbloquear')}>
                    Bloqueio e Desbloqueio de Usuários
                  </button>
                </>
              )}

              {perfil === profile.gestorOrgao && (
                <button className='br-button' type='button' onClick={() => props.history.push('/gerenciarGestoresRegionais')}>
                  Gestão de Gestores Regionais
                </button>
              )}

              {(perfil === profile.gestorOrgao) && (
                <button className='br-button' type='button' style={{ marginTop: '30px' }} onClick={() => props.history.push('/gerenciarUnidades')}>
                  Gestão de Unidades
                </button>
              )}

              {(perfil === profile.gestorRegional) && (
                <button className='br-button' type='button' style={{ marginTop: '30px' }} onClick={() => props.history.push('/gerenciaratendentes')}>
                  Gestão de Atendente
                </button>
              )}

              {perfil === profile.gestorRegional && (
                <>
                  <button className='br-button' type='button' style={{ marginTop: '30px' }} onClick={() => props.history.push('/horariosUnidades')}>
                    Gestão de horários de exceção para Unidade
                  </button>
                </>
              )}

              {perfil !== profile.atendente && (
                <>
                  <button className='br-button' type='button' style={{ marginTop: '30px' }} onClick={() => props.history.push('/horarios')}>
                    {{
                      [profile.gestorOrgao]: 'Gestão de Horários para Gestores Regionais',
                      [profile.gestorRegional]: 'Gestão de Horários para Atendentes',
                      [profile.gestorSistema]: 'Gestão de Horários de Atendimentos Excepcionais',
                     }[perfil] || ''
                    }
                  </button>
                  <button className='br-button' type='button' style={{ marginTop: '30px' }} onClick={() => props.history.push('/relatorios')}>
                    Relatórios
                  </button>
                </>
              )}
 
              {(perfil === profile.gestorSistema || perfil === profile.gestorOrgao) && (
                <button className='br-button' type='button' style={{ marginTop: '30px' }} onClick={() => props.history.push('/consultaratendimento')}>
                  Consulta de Atendimento
                </button>
              )} 

              {(process.env.REACT_APP_EST_ENVIRONMENT === 'v3' || process.env.EST_ENVIRONMENT === 'v3') && (
                <button className='br-button' type='button' style={{ marginTop: '30px' }} onClick={() => props.history.push('/gerenciarperfil')}>
                  Gerenciar Perfil (Validação)
                </button>
              )}
              
            </div>
          </ContentContainer1>

          {perfil !== profile.gestorSistema && (
            <ContentContainer2>
              <form onSubmit={handleSubmit((data) => {
                    if (isCpfValid(data.cpf)) {
                      onSubmit(data);
                    } else {
                      setShowInvalidErroCpf(true);
                    } 
                  }
                )}>
                <ContentContainer21>
                  <label>Quem está solicitando a Conta gov.br?</label>
                </ContentContainer21>

                <ContentContainer22>
                  <ContentContainer221>
                    <SelectButton select={watchSelect === 'cidadao'}>
                      <label htmlFor='cidadao'>
                        <img className='content_container_2_img1' src={citizenImgSelect} alt='cidadaoimg' />
                      </label>
                      <input ref={register({ required: true })} type='radio' id='cidadao' name='person' value='cidadao' defaultChecked={person} />
                    </SelectButton>

                    <Text>Cidadão</Text>
                  </ContentContainer221>

                  <ContentContainer222>
                    <SelectButton select={watchSelect === 'procurador'}>
                      <label htmlFor='procurador'>
                        <img className='content_container_2_img2' src={attorneyImgSelect} alt='procuradorimg' />
                      </label>
                      <input ref={register({ required: true })} type='radio' id='procurador' name='person' value='procurador' />
                    </SelectButton>

                    <Text>Procurador</Text>
                  </ContentContainer222>
                </ContentContainer22>

                <ContentContainer23>
                  <div className='br-input'>
                    <label htmlFor='cpf'>{watchSelect === 'cidadao' ? 'CPF do Cidadão' : 'CPF do Procurador'}</label>
                    <input
                      id='cpf'
                      name='cpf'
                      placeholder='Digite somente números'
                      type='text'
                      maxLength='14'
                      defaultValue=''
                      ref={register({
                        required: { value: true, message: 'Digite o CPF.' }
                      })}
                      value={cpfMask(cpf)}
                      onInput={e => { 
                        setCpf(e.target.value);
                        setShowInvalidErroCpf(false); 
                      }} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                    />
                  </div>
                  {errors?.cpf && (
                    <span className='feedback danger' role='alert'>
                      <i className='fas fa-times-circle' aria-hidden='true'>
                        <img src={FailIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
                      </i>
                      {
                        <script>
                          {(document.getElementById('cpfattorney').style.display = 'none')}
                          {(document.getElementById('cpfcitizen').style.display = 'none')}
                        </script>
                      }
                      <label>{errors.cpf.message}</label>
                    </span>
                  )}
                  {showInvalidErroCpf &&
                      <>
                          <span className="feedback danger" role="alert">
                              <i className="fas fa-times-circle" aria-hidden="true">
                                  <img
                                      src={FailIcon}
                                      style={{ paddingBottom: '5px' }}
                                      alt="failicon"
                                  />
                              </i>
                              CPF Inválido
                          </span>
                      </>
                  }
                  <span className='feedback danger' role='alert' id='cpfattorney' style={{ display: 'none' }}>
                    <i className='fas fa-times-circle' aria-hidden='true'>
                      <img src={FailIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
                    </i>
                    <label id='cpfattorneylabel'></label>
                  </span>
                  <span className='feedback danger' role='alert' id='cpfcitizen' style={{ display: 'none' }}>
                    <i className='fas fa-times-circle' aria-hidden='true'>
                      <img src={FailIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
                    </i>
                    <label id='cpfcitizenlabel'></label>
                  </span>

                  <ContentContainer24>
                    {!loading ? (
                      <button className='br-button primary' type='submit'>
                        Consultar
                      </button>
                    ) : (
                      <div className='loading medium'></div>
                    )}
                  </ContentContainer24>
                </ContentContainer23>
              </form>
            </ContentContainer2>
          )}
        </ContentContainer>
      </GlobalContainer>
    </>
  )
}
