import React, { useState } from 'react';
import {
    GlobalContainer,
    ContentContainer,
    ReportContainer,
    TitleContainer,
    Title,
    Subtitle,
    IntervalTitle
} from '../styles';
import AlertIcon from '../../assets/alert_icon2.png';
import { formatDate, today } from '../../utils';
import ReportInputDataInterval from '../../Components/ReportInputDataInterval'
import ModalWarn from '../../Components/Modal/Warn/index'

export default function CallsByAgencyManagerReport(props) {
    const [dataInterval, setDataInterval] = useState({ dt_inicio: "", dt_fim: today() });
    const [agrupador, setAgrupador] = useState('nao')
    const [loadingReport, setLoadingReport] = useState(false);
    const [result, setResult] = useState();
    const [searchError, setSearchError] = useState(false);
    const [searchErrorMessage, setSearchErrorMessage] = useState("");
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    const agrupadores = [
        { value: 'nao', label: 'Não agrupar' },
        { value: 'mes', label: 'Agrupar por mês' },
        { value: 'dia', label: 'Agrupar por dia' }
    ]

    const dominio_agrupadores = { mes: 'Mês', dia: 'Dia' }
    const user = JSON.parse(localStorage.getItem('user'));

    const onSubmitSearch = async () => {
        setLoadingReport(true);
        setSearchError(false);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': user ? user.token : ""
            }),

            body: JSON.stringify({
                cpf_gestor: user ? user.cpf_atendente : "",  //Neste caso, o cpf_atendente se refere ao gestor que está gerando o relatório
                codigo_orgao: user.codigo_orgao,
                dt_inicio: dataInterval.dt_inicio,
                dt_fim: dataInterval.dt_fim,
                agrupador: agrupador,
            }),
        };

        await fetch(`${process.env.REACT_APP_API_URL}/gestao/relAtendimentosOrgao`, requestOptions).then(
            function (response) {
                if (response.ok) {
                    response.json().then(function (data) {
                        setResult(data);
                    });
                } else if (response.status === 422) {
                    response.json().then(function (data) {
                        setSearchError(true);
                        if (!!data.errorCode && !!data.messages) {
                            setSearchErrorMessage(data.messages[0].message);
                        } else if (!!data.errorCode && !!data.message) {
                            setSearchErrorMessage(data.message);
                        } else {
                            // inserir mensagem genérica
                            setSearchErrorMessage('Houve algum problema no servidor.');
                        }
                    });
                } else if (response.status === 403) {
                    response.json().then(function (data) {
                      setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                      setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                      setModalInvalidSessionError(true)
                    })
                } else {
                    setSearchError(true);
                    setSearchErrorMessage('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                }
            }).catch(function (err) {
                console.error('Failed retrieving information', err);
            });

        setLoadingReport(false);
    }
    return (
        <GlobalContainer>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <ContentContainer>
                <TitleContainer>
                    <Title>Total de atendimentos do orgão ( concluídos e não concluídos )</Title>
                </TitleContainer>
                <ReportInputDataInterval
                    onSubmitSearch={onSubmitSearch}
                    dataInterval={dataInterval}
                    setDataInterval={setDataInterval}
                    setAgrupador={setAgrupador}
                    agrupadores={agrupadores}
                    loadingReport={loadingReport}
                    setResult={setResult}
                    result={result}
                    modalError={searchError}
                    setModalError={setSearchError}
                    messageError={searchErrorMessage}
                    history={props.history}
                />
                {result?.length > 0 ?
                    <ReportContainer>
                        {agrupador !== 'nao' && <Subtitle><label>{user.orgao}</label></Subtitle>}
                        <IntervalTitle>
                            {dataInterval.dt_inicio && dataInterval.dt_fim &&
                                <label>Período: de {formatDate(dataInterval.dt_inicio)} à {formatDate(dataInterval.dt_fim)}</label>
                            }
                            {dataInterval.dt_inicio && !dataInterval.dt_fim &&
                                <label>Período: de {formatDate(dataInterval.dt_inicio)} à {formatDate(today())}</label>
                            }
                            {!dataInterval.dt_inicio && (dataInterval.dt_fim && formatDate(dataInterval.dt_fim) !== formatDate(today())) &&
                                <label>Período: até {formatDate(dataInterval.dt_fim)} </label>
                            }
                        </IntervalTitle>
                        <div className="br-table" data-search="data-search" data-selection="data-selection">
                            <table>
                                <thead>
                                    <tr>
                                        {agrupador === 'nao' && <th> Órgão </th>}
                                        {agrupador !== 'nao' && <th>{dominio_agrupadores[agrupador]}</th>}
                                        <th> Concluídos </th>
                                        <th> Não concluídos </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {result.map((r, i) => (
                                        <tr key={i}>
                                            {agrupador === 'nao' && <td> {r.NOME_ORGAO} </td>}
                                            {agrupador !== 'nao' && <td style={{ 'textAlign': 'center' }}>{r.AGRUPADOR}</td>}
                                            <td>{r.QTDE_ATENDIMENTO_CONCLUIDOS || 0}</td>
                                            <td>{r.QTDE_ATENDIMENTO_NAO_CONCLUIDOS || 0}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </ReportContainer>
                    :
                    <>
                        {result?.length === 0 &&
                            <span className="feedback warning" role="alert" id="listresult">
                                <i className="fas fa-times-circle" aria-hidden="true">
                                    <img
                                        src={AlertIcon}
                                        style={{ paddingBottom: '5px' }}
                                        alt="failicon"
                                    />
                                </i>
                                <label id="listresultlabel">Nenhum registro encontrado para os parâmetros informados.</label>
                            </span>
                        }
                    </>
                }
            </ContentContainer>
        </GlobalContainer>
    );
}