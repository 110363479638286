import { profile } from '../../constants';
import {
    GlobalContainer,
    ContentContainer,
    TitleContainer,
    Title,
    ButtonsContainer
} from './styles';
import ViewIcon from '../../assets/eye_icon.png';
import { Link } from 'react-router-dom';

export default function MenuReport(props) {

    const perfil = JSON.parse(localStorage.getItem('user')).perfil;

    return (
        <GlobalContainer>
            <ContentContainer>
                <TitleContainer>
                    <Title>Relatórios Disponíveis</Title>
                </TitleContainer>
                <div className="br-table" data-search="data-search" data-selection="data-selection">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ 'width': '70%' }}> Relatório </th>
                                <th> Ação </th>
                            </tr>
                        </thead>
                        <tbody>
                            {perfil === profile.gestorSistema && <>
                                <tr>
                                    <td>Total de atendimentos por órgão concluído e não concluído</td>
                                    <td>
                                        <Link to='/relAtendimentosPorOrgao'>
                                            <img src={ViewIcon} alt="viewIcon" title="Visualizar" />
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total de gestores cadastrados por órgão (ativos e cancelados)</td>
                                    <td>
                                        <Link to='/relGestoresPorOrgao'>
                                            <img src={ViewIcon} alt="viewIcon" title="Visualizar" />
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total de atendentes cadastrados por órgão (ativos e cancelados) por mês</td>
                                    <td>
                                        <Link to='/relAtendentesPorOrgaoMes'>
                                            <img src={ViewIcon} alt="viewIcon" title="Visualizar" />
                                        </Link>
                                    </td>
                                </tr>
                            </>}
                            {(perfil === profile.gestorOrgao) && <>
                                <tr>
                                    <td>Total de atendimentos do orgão (concluídos e não concluídos)</td>
                                    <td>
                                        <Link to='/relAtendimentosOrgao'>
                                            <img src={ViewIcon} alt="viewIcon" title="Visualizar" />
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total de atendentes ativos e cancelados por mês</td>
                                    <td>
                                        <Link to='/relAtendentesDoOrgaoPorMes'>
                                            <img src={ViewIcon} alt="viewIcon" title="Visualizar" />
                                        </Link>
                                    </td>
                                </tr>
                            </>}
                            {((perfil === profile.gestorSistema) || (perfil === profile.gestorOrgao)) && <>
                                <tr>
                                    <td>Total de atendentes ativos e cancelados por gestor por mês</td>
                                    <td>
                                        <Link to='/relAtendentesPorGestorMes'>
                                            <img src={ViewIcon} alt="viewIcon" title="Visualizar" />
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total de atendentes ativos e cancelados por unidade, por gestor e por mês - a partir de 24/01/2025</td>
                                    <td>
                                        <Link to='/relAtendentesPorGestorMesUnidade'>
                                            <img src={ViewIcon} alt="viewIcon" title="Visualizar" />
                                        </Link>
                                    </td>
                                </tr>
                            </>}
                            {(perfil === profile.gestorSistema) && <>
                                <tr>
                                    <td>Unidades de atendimento por órgãos</td>
                                    <td>
                                        <Link to='/relUnidadesPorOrgao'>
                                            <img src={ViewIcon} alt="viewIcon" title="Visualizar" />
                                        </Link>
                                    </td>
                                </tr>                                
                            </>}
                            {(perfil === profile.gestorRegional) && <>
                                <tr>
                                    <td>Total de atendimentos da unidade (concluídos e não concluídos)</td>
                                    <td>
                                        <Link to='/relAtendimentosUnidade'>
                                            <img src={ViewIcon} alt="viewIcon" title="Visualizar" />
                                        </Link>
                                    </td>
                                </tr>
                            </>}
                            {((perfil === profile.gestorOrgao) || (perfil === profile.gestorRegional)) && <>
                                <tr>
                                    <td>Total atendimentos por fluxo (concluído e não concluído)</td>
                                    <td>
                                        <Link to='/relAtendimentosPorFluxo'>
                                            <img src={ViewIcon} alt="viewIcon" title="Visualizar" />
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total atendimentos por atendente concluídos e não concluídos</td>
                                    <td>
                                        <Link to='/relAtendimentosPorAtendente'>
                                            <img src={ViewIcon} alt="viewIcon" title="Visualizar" />
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total atendimentos por atendente concluídos e não concluídos por unidade - a partir de 24/01/2025</td>
                                    <td>
                                        <Link to='/relAtendimentosPorAtendentePorUnidade'>
                                            <img src={ViewIcon} alt="viewIcon" title="Visualizar" />
                                        </Link>
                                    </td>
                                </tr>
                            </>}
                        </tbody>
                    </table>
                </div>
                <ButtonsContainer>
                    <button className="br-button secundary" type="button" onClick={() => props.history.push("/pesquisarcidadao")}>
                        Voltar
                    </button>
                </ButtonsContainer>
            </ContentContainer>
        </GlobalContainer>
    );
}