import styled from 'styled-components';

export const ReportParameterContainer = styled.div `
    @media print {
        div {
            display: none !important;
        }        
    }
`;

export const DoubleListContainer = styled.div `
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DoubleListSubContainer = styled.div `
    display: flex;
    gap: 20px;
`;

export const ListContainerLeft = styled.div `
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px; 
    input {
        margin-top: 10px;
        border: 1px solid #888;
        border-radius: 6px;
        width: 100%;
        font-size: var(--font-size-scale-base);
        }
`;

export const ListContainerRight = styled.div `
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px; 
    input {
        margin-top: 10px;
        border: 1px solid #888;
        border-radius: 6px;
        width: 100%;
        font-size: var(--font-size-scale-base);
        }
`;

export const ListContainer = styled.div `
    width: 400px;
    height: 160px;
    background: white;
    margin-top: 10px;
    padding: 10px;    
    overflow-y: auto;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    li {
        padding: 2px;
        background: #007bff;
        color: white;
        margin: 5px 0;
        cursor: pointer;
        text-align: center;
        border-radius: 5px;
        transition: background 0.3s;
    }
    li:hover {
        background: #0056b3;
    }
`;

export const Ul = styled.ul `
    list-style: none;
    padding: 0;
    margin: 0;
`;







export const Subtitle = styled.div `
    padding: 0px 0px 0px;
    margin-bottom: 15px;

    img { margin-bottom: 7px; margin-right: 7px}}
    
    label {
       font-size: 1.5em;
       color: #0c326f;
    }
`;

export const Line = styled.div `
    margin-top: 5px;
    border-bottom: 1px solid #cccccc;
`;

export const InputContainer = styled.div `
    display: grid;
    margin-right: 30px;

    input {
        padding: 0px 7px 0px;
        font-size: 14px;
        font-weight: normal;
        color: #333333;
    }

    //border: 1px solid;
    //border-color: red;
`;

export const SearchButtonsContainer = styled.div `
    width: 100%;
    padding: 20px 0 0;
    text-align: right;

    button:nth-child(2){ 
        margin-left: 10px;
    }

    button:nth-child(3){ 
        margin-left: 10px;
    }
`;